import React, { useState } from 'react';
import { useMutation } from 'react-query';
import styled, { keyframes } from 'styled-components';
import { deleteUser } from '../features/users/api/deleteUser';
import { showError } from '../qcp-utils';
import { showConfirmation } from '../qcp-utils/lib/confirmation';
import { useAuth } from '../hooks';
import { useNavigate } from 'react-router-dom';
import { auth } from '../config/firebase';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const DashboardContainer = styled.div`
  max-width: 1200px;
  margin: 80px auto 0;
  padding: 40px;
  background-color: #f8f9fa;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  animation: ${fadeIn} 0.5s ease-out;
`;

const Title = styled.h1`
  font-size: 32px;
  color: #2c3e50;
  margin-bottom: 30px;
  font-weight: 300;
`;

const EmailList = styled.div`
  display: grid;
  gap: 20px;
  margin-bottom: 40px;
`;

const EmailItem = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
`;

const EmailSubject = styled.h3`
  font-size: 18px;
  color: #34495e;
  margin-bottom: 5px;
`;

const EmailSender = styled.p`
  font-size: 14px;
  color: #7f8c8d;
  margin-bottom: 10px;
`;

const EmailPreview = styled.p`
  font-size: 14px;
  color: #95a5a6;
`;

const DeleteButton = styled.button`
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #c0392b;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const ConfirmationMessage = styled.div`
  background-color: #2ecc71;
  color: white;
  padding: 15px;
  border-radius: 8px;
  margin-top: 20px;
  animation: ${fadeIn} 0.5s ease-out;
`;


function Dashboard() {
  const { mutateAsync: deleteHandler } = useMutation(deleteUser, {
    onError: showError,
  });


  const navigate = useNavigate();

  const {user, profile} = useAuth();
  const mockEmails = [
    { id: 1, title: "Your Profile", email: user?.email, username: profile?.username, firstName: profile?.firstName, lastName: profile?.lastName},
  ];

  const handleDeleteAccount = () => {
    showConfirmation(async () => {
      await deleteHandler(profile._id);
      auth.signOut();
      navigate('/');
    }, "Delete");
  };

  return (
    <DashboardContainer>
      <Title>Welcome to Your Dashboard</Title>
      
      <EmailList>
        {mockEmails.map(account => (
          <EmailItem key={account.id}>
            <EmailSubject>{account.title}</EmailSubject>
            <EmailSender>email: {account.email}</EmailSender>
            <EmailSender>username:  {account.username}</EmailSender>
            <EmailSender>First Name:  {account.firstName}</EmailSender>
            <EmailSender>Last Name:  {account.lastName}</EmailSender>
          </EmailItem>
        ))}
      </EmailList>

      <DeleteButton onClick={handleDeleteAccount}>Delete Account</DeleteButton>
    </DashboardContainer>
  );
}

export default Dashboard;