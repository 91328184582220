import { clearAccessToken, getAccessToken } from "./accessToken";

export const baseURL = process.env.REACT_APP_BASE_URL;
export const imageUrl = `${baseURL}/upload/file`;

export async function fetchExtra(
  input: string,
  init: RequestInit,
  useAuth: boolean = true,
  signal: AbortSignal | undefined = undefined
) {
  if (useAuth) {
    const token = getAccessToken();
    if (token) {
      if (!init?.headers) {
        init!.headers = {};
      }

      init.headers["Authorization"] = `Bearer ${token}`;
    }
  }

  const response = await fetch(`${baseURL}/${input}`, { ...init, signal });

  switch (response.status) {
    case 200:
    case 201:
      return {
        status: response.status,
        response: response.json(),
      };
    case 204:
      return {
        status: response.status,
      };
    case 401:
      clearAccessToken();
      throw await response.json();
    default:
      throw await response.json();
  }
}
