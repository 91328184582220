import { auth } from "../config/firebase.ts";
import { createContext, useContext, useEffect, useState } from "react";
import { User } from "firebase/auth";

import { User as FirebaseUser } from "@firebase/auth";
import { clearAccessToken, hasAccessToken, setAccessToken } from "../qcp-utils";
import { getProfile } from "../features/auth/api/getProfile.ts";
import { useMutation } from "react-query";


interface UserWithAccessToken extends FirebaseUser {
  accessToken: string;
  reloadUserInfo: {
    customAttributes: string;
  };
}

const AuthContext = createContext(
  {
    user: null,
    profile: null,
    loading: true,
    hasToken: false,
  } as { user: User | null; profile: any; loading: boolean, hasToken: boolean }
);

export const AuthProvider = ({ 
  children
}: {
  children: React.ReactNode;
}
) => {
  const [currentUser, setUser] = useState<User | null>(null);
  const [profile, setProfile] = useState<any>({});
  const [loading, setLoading] = useState(true);

  const { mutateAsync: getProfileMutation } = useMutation(getProfile);

  useEffect(() => {
    auth.authStateReady().then(() => {
      setLoading(false);
    });

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
            setUser(user);
            setAccessToken((user as UserWithAccessToken).accessToken);

            getProfileMutation().then((profile) => {
              if (profile) {
                setProfile(profile);
              }
            });
        });

        const interval = setInterval(() => {
          user.getIdTokenResult(true).then((idTokenResult) => {
              setAccessToken((user as UserWithAccessToken).accessToken);
          });
        }, 30 * 60 * 1000); // 30 minutes

        return () => clearInterval(interval);
      } else {
        setUser(null);
        clearAccessToken();
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ user: currentUser , profile, loading, hasToken: hasAccessToken() }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
