// src/components/Footer.js
import React from 'react';
import styled from 'styled-components';

const FooterWrapper = styled.footer`
  background-color: #f5f5f7;
  color: #86868b;
  padding: 20px;
  text-align: center;
`;

const FooterText = styled.p`
  font-size: 12px;
  line-height: 1.33337;
  margin: 0;
`;

function Footer() {
  return (
    <FooterWrapper>
      <FooterText>&copy; 2024 InterUltimate. All rights reserved.</FooterText>
    </FooterWrapper>
  );
}

export default Footer;