import Swal from "sweetalert2";

// Function to display a generic error message
export const showError = (error: any) => {
  Swal.fire({
    icon: "error",
    title: "Error",
    text: error.message,
    confirmButtonColor: "#6C6CF2",
  });
};
