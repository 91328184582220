// src/pages/HomePage.js
import styled from 'styled-components';


const HomePageWrapper = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #1d1d1f;
  background-color: #fbfbfd;
`;

const Hero = styled.section`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: url('/api/placeholder/1920/1080');
  background-size: cover;
  background-position: center;
`;

const HeroTitle = styled.h1`
  font-size: 56px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const HeroSubtitle = styled.h2`
  font-size: 28px;
  font-weight: 400;
  margin-bottom: 20px;
  max-width: 600px;
`;

const CTAButton = styled.a`
  display: inline-block;
  background-color: #0071e3;
  color: white;
  padding: 12px 24px;
  border-radius: 980px;
  text-decoration: none;
  font-size: 18px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0077ed;
  }
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  padding: 100px 50px;
  background-color: white;
`;

const FeatureCard = styled.div`
  text-align: center;
  padding: 40px;
  border-radius: 18px;
  background-color: #fbfbfd;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
`;

const FeatureIcon = styled.div`
  font-size: 48px;
  margin-bottom: 20px;
`;

const FeatureTitle = styled.h3`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const FeatureDescription = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: #86868b;
`;

const FeatureSection = styled.section<{
  bgColor?: string;
}>`
  padding: 100px 0;
  background-color: ${props => props.bgColor || 'white'};
`;

const FeatureContent = styled.div`
  max-width: 980px;
  margin: 0 auto;
  padding: 0 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FeatureText = styled.div<{
  imageRight?: boolean;
  }>`
  flex: 1;
  padding-right: ${props => props.imageRight ? '50px' : '0'};
  padding-left: ${props => props.imageRight ? '0' : '50px'};
  order: ${props => props.imageRight ? 1 : 2};

  @media (max-width: 768px) {
    order: 2;
    padding: 0;
    text-align: center;
  }
`;

const FeatureSectionTitle = styled.h2`
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 20px;
`;

const FeatureSectionDescription = styled.p`
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
`;

const FeatureImage = styled.img<
  { imageRight?: boolean }
>`
  flex: 1;
  max-width: 50%;
  height: auto;
  order: ${props => props.imageRight ? 2 : 1};

  @media (max-width: 768px) {
    order: 1;
    max-width: 100%;
    margin-bottom: 30px;
  }
`;

function HomePage() {
  const features = [
    { icon: '🚀', title: 'Lightning Fast', description: 'Experience unparalleled speed and performance.' },
    { icon: '🔒', title: 'Fort Knox Security', description: 'Your data is protected with state-of-the-art encryption.' },
    { icon: '🎨', title: 'Beautiful Design', description: 'Intuitive and visually stunning user interface.' },
    { icon: '🔋', title: 'All-Day Battery', description: 'Power that lasts as long as your longest day.' },
    { icon: '🌐', title: 'Seamless Integration', description: 'Works flawlessly with all your favorite apps and services.' },
    { icon: '🔄', title: 'Constant Updates', description: 'Always improving with regular feature updates.' },
  ];

  return (
    <HomePageWrapper>
      <Hero>
        <HeroTitle>Wake Up to a Smarter You</HeroTitle>
        <HeroSubtitle>Challenge your mind, achieve your goals, and connect with the world—all starting with your morning alarm.</HeroSubtitle>
        <CTAButton href="#features">Explore Features</CTAButton>
      </Hero>

      <FeaturesGrid id="features">
        {features.map((feature, index) => (
          <FeatureCard key={index}>
            <FeatureIcon>{feature.icon}</FeatureIcon>
            <FeatureTitle>{feature.title}</FeatureTitle>
            <FeatureDescription>{feature.description}</FeatureDescription>
          </FeatureCard>
        ))}
      </FeaturesGrid>

      <FeatureSection>
        <FeatureContent>
          <FeatureText>
            <FeatureSectionTitle>Wake Up Smarter</FeatureSectionTitle>
            <FeatureSectionDescription>
              Start your day with a challenge. Our quiz alarm wakes you up by engaging your brain with fun quizzes, ensuring you never hit snooze again. Say goodbye to groggy mornings and hello to a sharper, more alert you.
            </FeatureSectionDescription>
          </FeatureText>
          <FeatureImage src="https://picsum.photos/600/400" alt="Wake Up Smarter" />
        </FeatureContent>
      </FeatureSection>

      <FeatureSection bgColor="#f5f5f7">
        <FeatureContent>
          <FeatureImage src="https://picsum.photos/600/400" alt="Quiz with the World" imageRight />
          <FeatureText imageRight>
            <FeatureSectionTitle>Quiz with the World</FeatureSectionTitle>
            <FeatureSectionDescription>
              Compete with friends and strangers alike. Solve quizzes on your favorite topics, sharpen your mind, and see how you stack up globally—all from your morning alarm. Turn your wake-up routine into a fun, competitive experience that keeps you coming back for more.
            </FeatureSectionDescription>
          </FeatureText>
        </FeatureContent>
      </FeatureSection>

      <FeatureSection>
        <FeatureContent>
          <FeatureText>
            <FeatureSectionTitle>Personal Goals, Perfectly Managed</FeatureSectionTitle>
            <FeatureSectionDescription>
              Track every step toward your goals. Whether it's routines, to-dos, scheduling, or journaling, our all-in-one AI-powered app helps you build better habits and achieve your dreams. Let our intelligent system guide you towards success, one task at a time.
            </FeatureSectionDescription>
          </FeatureText>
          <FeatureImage src="https://picsum.photos/600/400" alt="Personal Goals Management" />
        </FeatureContent>
      </FeatureSection>

      <FeatureSection bgColor="#f5f5f7">
        <FeatureContent>
          <FeatureImage src="https://picsum.photos/600/400" alt="AI-Generated Quizzes" imageRight />
          <FeatureText imageRight>
            <FeatureSectionTitle>AI-Generated Quizzes, Daily</FeatureSectionTitle>
            <FeatureSectionDescription>
              No more repeating the same questions. Our AI generates fresh quizzes daily across various topics, keeping your mind sharp and entertained every morning. From history to pop culture, science to sports, wake up to a new learning experience every day.
            </FeatureSectionDescription>
          </FeatureText>
        </FeatureContent>
      </FeatureSection>

      <FeatureSection>
        <FeatureContent>
          <FeatureText>
            <FeatureSectionTitle>Achieve Any Dream</FeatureSectionTitle>
            <FeatureSectionDescription>
              From finding love to losing weight, our AI-backed dream-achieving system is designed to help you take meaningful steps toward any personal goal. Set your targets, and let our intelligent algorithms create a personalized roadmap to success, adjusting as you progress.
            </FeatureSectionDescription>
          </FeatureText>
          <FeatureImage src="https://picsum.photos/600/400" alt="Achieve Any Dream" />
        </FeatureContent>
      </FeatureSection>

      {/* <FeatureSection bgColor="#f5f5f7">
        <FeatureContent>
          <FeatureImage src="/api/placeholder/500/300" alt="Socialize Your Progress" imageRight />
          <FeatureText imageRight>
            <FeatureSectionTitle>Socialize Your Progress</FeatureSectionTitle>
            <FeatureSectionDescription>
              Share your journey with followers. Keep track of daily life, routines, and goals, and inspire others by showing them how you manage success. Build a supportive community, celebrate milestones together, and motivate each other to reach new heights.
            </FeatureSectionDescription>
          </FeatureText>
        </FeatureContent>
      </FeatureSection> */}
    </HomePageWrapper>
  );
}

export default HomePage;