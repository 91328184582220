import Swal from "sweetalert2";

export const showConfirmation = (
  handleDelete: (id?: string) => void,
  confirmButtonText = "Confirm",
  confirmationText = "Are you sure?"
) => {
  Swal.fire({
    text: confirmationText,
    icon: "warning",
    showCancelButton: true,
    showConfirmButton: true,
    confirmButtonColor: "#d33",
    cancelButtonColor: "#6C6CF2",
    confirmButtonText,
    focusCancel: false,
    // reverseButtons: true,
  }).then((result) => {
    if (result.isConfirmed) {
      handleDelete();
    }
  });
};
